<template>
  <div class="order">
    <!-- 最上面選擇區塊 -->
    <div class="container__type">
      <div :class="typeStatus === 0 ? 'active' : 'no-active'" @click="getStatus()">
        进行中
      </div>
      <div :class="typeStatus === 1 ? 'active' : 'no-active'" @click="getMethod()">
        已完成
      </div>
    </div>
    <!-- 第二行選擇區塊 -->
    <div class="container__select" v-if="typeStatus == 0">
      <div :class="selectStatus === '' ? 'active' : 'no-active'" @click="selectStatus = ''">
        全部
      </div>
      <div :class="selectStatus === 0 ? 'active' : 'no-active'" @click="selectStatus = 0">
        进行中
      </div>
      <div :class="selectStatus === 1 ? 'active' : 'no-active'" @click="selectStatus = 1">
        申诉中
      </div>
    </div>
    <div class="container__select" v-else>
      <div :class="selectStatus === '' ? 'active' : 'no-active'" @click="selectStatus = ''">
        全部
      </div>
      <div :class="selectStatus === 1 ? 'active' : 'no-active'" @click="selectStatus = 1">
        充值
      </div>
      <div :class="selectStatus === 0 ? 'active' : 'no-active'" @click="selectStatus = 0">
        提现
      </div>
    </div>
    <div class="container__content" ref="list">
      <div v-if="StatusList != ''" class="container__statusList">
        <div class="wrap__content" v-for="(item, i) in StatusList" :key="i" @click="goOtherPage(item)">
          <div class="box__content">
            <div class="img">
              <img :src="getAvatar(item.traderAvatar)" alt="" />
            </div>
            <div class="info">
              <div class="traderName">{{ item.traderName }}</div>
              <div class="status">
                <span :class="item.generationMethod == 0 ? 'red' : 'red'">{{ item.abnormal == 1 ? "申訴中" : "進行中" }}</span>
                <span>¥ {{ item.orderAmount }} </span>
              </div>
            </div>
          </div>
          <div class="box__status">
            <div>
              {{ item.statusDesc }}
            </div>
            <div>
              {{ item.createdAt }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="MethodList != ''">
        <div
          class="wrap__content"
          v-for="(item, i) in MethodList"
          :key="i"
          @click="
            $router.push({
              path: '/orderInfo',
              query: { orderId: item.id, comeFrom: '/order' },
            })
          "
        >
          <div class="box__content">
            <div class="img">
              <img :src="getAvatar(item.traderAvatar)" alt="" />
            </div>
            <div class="info">
              <div class="traderName">{{ item.traderName }}</div>
              <div class="status">
                <span :class="item.generationMethod == 0 ? 'green' : 'red'">{{ item.generationMethodDesc }}</span>
                <span>¥ {{ item.orderAmount }}</span>
              </div>
            </div>
          </div>
          <div class="box__status">
            <div>
              {{ item.statusDesc }}
            </div>
            <div>
              {{ item.completedAt }}
            </div>
          </div>
        </div>
      </div>

      <div class="tw-w-full" v-else>
        <NoData />
      </div>
    </div>
    <FooterMenu :typeStatus="typeStatus" />
  </div>
</template>
<script>
import FooterMenu from "@/components/FooterMenu.vue";
import NoData from "@/components/NoData.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "order",
  components: {
    FooterMenu,
    NoData,
  },
  data() {
    return {
      title: "订单",
      StatusList: [],
      MethodList: [],
      typeStatus: 0,
      selectStatus: "",
      StatusPage: 1,
      MethodPage: 1,
    };
  },
  computed: {
    ...mapState("orders", ["logListData"]),
  },
  watch: {
    typeStatus() {
      this.selectStatus = "";
      this.StatusList = [];
      this.StatusPage = 1;
      this.MethodList = [];
      this.MethodPage = 1;
    },
    selectStatus() {
      this.StatusList = [];
      this.StatusPage = 1;
      this.MethodList = [];
      this.MethodPage = 1;
      if (this.typeStatus === 0) {
        this.getStatus();
      } else if (this.typeStatus === 1) {
        this.getMethod();
      }
    },
    logListData() {
      if (this.typeStatus === 0) {
        this.StatusPage++;
        this.StatusList = this.StatusList.concat(this.logListData);
      } else if (this.typeStatus === 1) {
        this.MethodPage++;
        this.MethodList = this.MethodList.concat(this.logListData);
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    ...mapActions("orders", ["getLog"]),
    init() {
      this.getStatus();
    },
    getAvatar(avatar) {
      if (avatar) return avatar;
      return require("@/assets/images/public/ic_my_head.webp");
    },
    goOtherPage(item) {
      if (item.abnormal == 0) {
        if (item.userTransactionType == 1) {
          if (item.status == 0) {
            this.$router.push({
              path: "/orderInformation",
              query: { orderId: item.id, orderNo: item.orderNo },
            });
          } else if (item.status == 3) {
            this.$router.push({
              path: "/sellerConfirmation",
              query: { orderId: item.id, orderNo: item.orderNo },
            });
          }
        } else if (item.userTransactionType == 0) {
          this.$router.push({
            path: "/waitingForBuyersPayment",
            query: { orderId: item.id, orderNo: item.orderNo },
          });
        }
      } else if (item.abnormal == 1) {
        this.$router.push({
          path: "/appealDetail",
          query: { orderNo: item.orderNo },
        });
      }
    },
    getStatus() {
      this.typeStatus = 0;
      let data = {
        page: this.StatusPage,
        limit: 20,
        status: this.selectStatus,
        type: 0,
      };
      this.getLog(data);
    },
    getMethod() {
      this.typeStatus = 1;
      let data = {
        page: this.MethodPage,
        limit: 20,
        method: this.selectStatus,
        type: 1,
      };

      this.getLog(data);
    },
    handleScroll() {
      this.$refs.list.onscroll = () => {
        const list = this.$refs.list;
        if (list.scrollHeight === list.scrollTop + list.clientHeight) {
          if (this.typeStatus === 0) {
            this.getStatus();
          } else if (this.typeStatus === 1) {
            this.getMethod();
          }
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.container__type {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  div {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 1px solid rgb(255, 115, 0);
    font-weight: bold;

    &:nth-child(1) {
      border-radius: 10px 0 0 10px;
    }

    &:nth-child(2) {
      border-radius: 0 10px 10px 0;
    }

    &.active {
      color: var(--white);
      background-color: var(--orange);
    }
  }
}

.container__select {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-left: 0.9rem;
    margin-right: 0.9rem;
    font-weight: bold;

    &.active {
      color: var(--orange);
      border-bottom: 3px solid rgb(255, 115, 0);
    }
  }
}

.container__content {
  width: 100%;
  // height: 100%;
  height: 71vh;
  overflow: auto;
  display: flex;
  @media (min-width: 480px) {
    justify-content: center;
  }

  & > div {
    height: 100%;
    @media (min-width: 480px) {
      width: 100%;
    }
  }

  .wrap__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .box__content {
      display: flex;
      align-items: center;

      .img {
        width: 2.5rem;
        font-size: 0;
        margin-right: 0.5rem;

        img {
          width: 100%;
          border-radius: 50%;
        }
      }

      .info {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        font-weight: bold;
        align-self: stretch;

        .traderName {
          width: 100%;
          text-align: left;
          margin-bottom: 0.2rem;
        }

        .status {
          width: 100%;
          text-align: left;

          span:nth-child(1) {
            color: var(--white);
            font-size: 0.8rem;
            padding: 0.2rem;
            border-radius: 5px;
            margin-right: 0.2rem;

            &.red {
              background-color: var(--red);
            }

            &.green {
              background-color: var(--green);
            }
          }
        }
      }
    }

    .box__status {
      display: flex;
      flex-wrap: wrap;
      align-self: stretch;
      justify-content: flex-end;

      div {
        width: 100%;
        text-align: right;
        font-size: 0.8rem;
        color: var(--gray);
      }
    }
  }
}
.container__statusList {
  width: 100%;
}

.NoData {
  margin-right: 231px;
}
</style>
